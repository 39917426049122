// TextColorWrapper.js
import React, { useEffect } from 'react';

const TextColorWrapper = ({ children }) => {
  useEffect(() => {
    const textColorVal = sessionStorage.getItem('textColorVal');
    if (textColorVal == 0) {
      document.body.classList.add('changi');
      document.body.classList.remove('voncierge');
    } else {
      document.body.classList.add('voncierge');
      document.body.classList.remove('changi');
    }
  }, []);

  return <>{children}</>;
};

export default TextColorWrapper;
