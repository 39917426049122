import React, { useEffect, useState, useRef } from 'react';
import ButtonWhite from '../Button/ButtonWhite';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons'
import { getHeaders } from '../../utils/apiUtils';
import { updateFavicon } from '../../utils/favicon';
import KioskDevice from '../KioskDevice';
import ApiCallingToaster from '../Toaster/ApiCallingToaster';
import DynamicApiCallingToaster from '../Toaster/DynamicApiCallingToaster';

var home_Bg1
var home_Bg2
var company_name

const NotFoundPage = () => {
  const [defaultApiError, setDefaultApiError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(false);
  const [deviceType, setDeviceType] = useState('mobile');
  const deviceTypeRef = useRef('');
  const [logo, setLogo] = useState(sessionStorage.getItem('settingLogo') || null);
  const [loading, setLoading] = useState(true);
  const [kioskDevice, setKioskDevice] = useState()
  const [fontFamily, setFontFamily] = useState(''); 

  const currentUrl = window.location.href;
  console.log(currentUrl)

  const navigate = useNavigate();
  const handleBtnClick = () => {
    navigate('/')
  }
  const loadFont = (fontFamily) => {
    const fontFaces = {
      'NotoSans-Medium': 'NotoSans-Medium',  
      'Lato-Regular': 'Lato-Regular',
      'Raleway-Medium': 'Raleway-Medium',
      'Poppins-Medium': 'Poppins-Medium',
      'Inter-Medium': 'Inter-Medium',
      'OpenSans-Medium': 'OpenSans-Medium',
      'Times New Roman': 'Times New Roma',
      'Merriweather-Regular': 'Merriweather-Regular',
      'Oswald-Medium': 'Oswald-Medium',
      'Rubik-Medium': 'Rubik-Medium'
  };

    if (fontFaces[fontFamily]) {
      const fontFace = new FontFace(fontFaces[fontFamily], `url(${currentUrl}src/fonts/${fontFaces[fontFamily]}.woff2)`);
        console.log('fontFace', fontFace)
        fontFace.load().then((loadedFace) => {
            document.fonts.add(loadedFace);
            document.body.style.fontFamily = fontFaces[fontFamily];
        }).catch((error) => {
            console.error(`Failed to load font: ${fontFamily}`, error);
        });
    }
};
  const fetchGeneralSetting = async () => {
    try {
      setDefaultApiError(false);
      const settingUrl = `${process.env.REACT_APP_BASE_URL_API}general-setting`;
      const settingResponse = await fetch(settingUrl, {
        method: 'GET',
        headers: getHeaders(),
      });

      if (!settingResponse.ok) {
        const errorData = await settingResponse.json();
        let errorMessage = errorData.error.message;
        setApiError(true);
        setApiErrorMsg(errorMessage);
        return;
      }

      
      const settingData = await settingResponse.json();
      const settingLogo = settingData.data.company.logo;
      company_name = settingData.data.company.company_name;

      home_Bg1 = settingData.data.company.theme_customization.home_background_color_1;
      home_Bg2 = settingData.data.company.theme_customization.home_background_color_2;

      const fetchedFontFamily = settingData.data.company.theme_customization.font;
      const mmUrl = settingData.data.company.maintenanceModeImage;
      const mmStatus = settingData.data.company.maintenance_mode;

      const kioskId = settingData.data.company.kiosk_device.kiosk_id;

      updateFavicon(settingData.data.company.favicon);
      if(kioskId == null || kioskId == '') {
          setKioskDevice(true);
      }
      else {
        setKioskDevice(false);
      }
      sessionStorage.setItem('settingLogo', settingLogo);
     
      sessionStorage.setItem('company_name', company_name);
    
      sessionStorage.setItem('home_Bg1', home_Bg1);
      sessionStorage.setItem('home_Bg2', home_Bg2);
      sessionStorage.setItem('mmUrl', mmUrl);
      sessionStorage.setItem('mmStatus', mmStatus);
      sessionStorage.setItem('kioskId', kioskId);


      // setBgImg(splashImg); 
      setFontFamily(fetchedFontFamily);
      loadFont(fetchedFontFamily);

      document.body.style.fontFamily = fetchedFontFamily;
      if (mmStatus == 1) {
        navigate('/maintenance'); 
      }
      setLogo(settingLogo);
      var companyClass = sessionStorage.getItem('textColorVal');
      if (companyClass == 0) {
        document.body.classList.add('changi');
        document.body.classList.remove('voncierge');
        // setShowHeader(false);
      } else {
        document.body.classList.add('voncierge');
        document.body.classList.remove('changi');
        // setShowHeader(true);
      }
    } catch (error) {
      setDefaultApiError(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (company_name) {
      const cleanedCompanyName = company_name.replace(/\s+/g, '');
      document.body.classList.add(cleanedCompanyName);
    }
  }, [company_name])

  const handleResize = async () => {
    const width = window.innerWidth;
    if (width <= 767) {
      setDeviceType('mobile');
      deviceTypeRef.current = 'mobile';
    } else if (width >= 768 && width <= 1024) {
      setDeviceType('tab');
      deviceTypeRef.current = 'tab';
    } else {
      setDeviceType('web');
      deviceTypeRef.current = 'web';
    }
    setTimeout(() => {
      sessionStorage.setItem('deviceSize', deviceTypeRef.current);
    }, 500)
    console.log('setDeviceType',  sessionStorage.getItem('deviceSize'))
  };
  useEffect(() => {
    handleResize();      
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchGeneralSetting();
  }, [])
  return (
    <div className='firstScreen errorPage' style={{backgroundImage : `linear-gradient(180deg, ${home_Bg1} 0%, ${home_Bg2} 100%)`}}>
      <div className='flex h-dvh items-center flex-col justify-center max-w-full px-[40px] lg:max-w-[1280px] m-auto'>
        <img src={logo} className='mb-6' />
        <FontAwesomeIcon icon={faFaceFrown} className='lg:text-[90px] sm:text-[100px] text-[70px] mb-[20px] sm:mb-0 text-white' />
        <h1 className='lg:text-[8em] sm:text-[6em] text-[3em] text-white font-bold mb-0 tracking-[11px]'>404</h1>
        <div className='text-center mb-[30px]'>
          <h2 className='sm:text-[36px] text-[22px] text-white mb-[20px]'>Page Not Found</h2>
          <p className='sm:text-[20px] text-[16px] text-gray-100 mb-[10px] break-words'>The requested URL <span className='text-white font-extrabold'>{currentUrl}</span> was not found on this server.</p>
          <p className=' text-white sm:text-[18px] text-[14px]'>That’s all we know.</p>
        </div>
        <ButtonWhite btnLabel="Back to home" btnClick={handleBtnClick} />
      </div>
      {kioskDevice && <KioskDevice /> }
      <div className='absolute bottom-4 right-4 p-2 bg-gray-700 text-white rounded hidden'>
              Device Size: {deviceType}
            </div>
        {defaultApiError && <ApiCallingToaster /> }
        {apiError && <DynamicApiCallingToaster content={apiErrorMsg}  />}
    </div>
  );
};

export default NotFoundPage;
