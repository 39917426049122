import React, { useState, useEffect, useRef } from 'react';

const SpeechRecognitionComponent = () => {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const recognitionRef = useRef(null); // Use useRef to store recognition instance

  const startListening = () => {
    if (recognitionRef.current) {
      setIsListening(true);
      recognitionRef.current.start();  // Start recognition
    }
  };

  const stopListening = () => {
    if (recognitionRef.current) {
      setIsListening(false);
      recognitionRef.current.stop();   // Stop recognition
    }
  };

  useEffect(() => {
    // Initialize SpeechRecognition when component mounts
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert('Sorry, your browser does not support the Web Speech API.');
      return;
    }

    const recognition = new SpeechRecognition();
    recognitionRef.current = recognition; // Store recognition in ref

    // Configure the recognition object
    recognition.lang = 'en-US'; // Set language (optional)
    recognition.interimResults = true; // Show interim results (optional)
    recognition.continuous = true; // Stop automatically after finishing

    recognition.onresult = (event) => {
      const speechToText = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join('');
      setTranscript(speechToText);
      console.log('speech', speechToText)
      
    };

    recognition.onend = () => {
      recognition.start();
      // setIsListening(false);
    };

    recognition.onerror = (event) => {
      console.error('Speech Recognition Error:', event.error);
      setIsListening(false);
    };

    // Cleanup function when the component unmounts
    return () => {
      recognition.abort();
    };
  }, []);

  return (
    <div>
      <h2>Web Speech API with React</h2>
      <button onClick={isListening ? stopListening : startListening}>
        {isListening ? 'Stop Listening' : 'Start Listening'}
      </button>
      <p>{transcript}</p>
    </div>
    
  );
};

export default SpeechRecognitionComponent;
